import imagen1 from '../../assets/img/landingPage/momentsCarousel/MaskGroup1.png'
import imagen2 from '../../assets/img/landingPage/momentsCarousel/MaskGroup2.png'
import imagen3 from '../../assets/img/landingPage/momentsCarousel/MaskGroup3.png'
import imagen4 from '../../assets/img/landingPage/momentsCarousel/MaskGroup4.png'

export const momentsCarousel = [
  {
    imgCarousel: imagen1,
    titleEvent: '',
  },
  {
    imgCarousel: imagen2,
    titleEvent: '',
  },
  {
    imgCarousel: imagen3,
    titleEvent: '',
  },
  {
    imgCarousel: imagen4,
    titleEvent: '',
  },
]
