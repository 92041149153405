{
  "name": "Español",
  "key": "es",
  "translation":{
    "Conoce los eventos del momento": "Conoce los eventos del momento",
    "Buscar idioma": "Buscar idioma",
    "Metaverso": "Metaverso",
    "Contacto": "Contacto",
    "Nosotros": "Nosotros",
    "Marketplace": "Tienda",
    "Todos": "Todos",
    "Inicio": "Inicio",
    "Ver más": "Ver más",
    "TEMA": "TEMA",
    "Tema": "Tema",
    "Consulta": "Consulta",
    "Problemas con Nuruk": "Problemas con Nuruk",
    "Problemas con mi wallet": "Problemas con mi wallet",
    "MI PERFIL": "MI PERFIL",
    "Bienvenido a": "Bienvenido a",
    "Seguimos en construcción": "Seguimos en construcción",
    "Eventos relacionados": "Eventos relacionados",
    "Selecciona categoria": "Selecciona categoria",
    "El contenido que buscas estará disponible próximamente. Estamos trabajando para darte la mejor experiencia.": "El contenido que buscas estará disponible próximamente. Estamos trabajando para darte la mejor experiencia.",
    "Para que las transacciones sean posibles, creamos tokens llamados 'Realities', los cuales se utilizan como moneda en nuestro metaverso. Con estos se realizan todas las transacciones financieras dentro de este multimetaverso. Podrás comprar parcelas( Realands y Patchas ), accesorios, ropa, servicios, y todo lo que se encuentre a la venta en Nuruk." :"Para que las transacciones sean posibles, creamos tokens llamados 'Realities', los cuales se utilizan como moneda en nuestro metaverso. Con estos se realizan todas las transacciones financieras dentro de este multimetaverso. Podrás comprar parcelas( Realands y Patchas ), accesorios, ropa, servicios, y todo lo que se encuentre a la venta en Nuruk.",
    "El reality token funciona dentro del ecosistema de Near. En este ecosistema es que se almacena el registro de transacciones en la blockchain. Tus realities se almacenan en tu Near Wallet, y se verán reflejados en tu perfil de Nuruk en la app de Reality Near. En nuestro metaverso puedes realizar infinidad de compras y ventas, adquirir tesoros o NFTs; y todo esto mediante transacciones de realities.": "El reality token funciona dentro del ecosistema de Near. En este ecosistema es que se almacena el registro de transacciones en la blockchain. Tus realities se almacenan en tu Near Wallet, y se verán reflejados en tu perfil de Nuruk en la app de Reality Near. En nuestro metaverso puedes realizar infinidad de compras y ventas, adquirir tesoros o NFTs; y todo esto mediante transacciones de realities.",
    "Nuruk es la principal y primera ciudad del multimetaverso 3D en Reality Near. El nombre se basa en la unión de dos palabras: new (nuevo en inglés) y uruk (la primera ciudad sobre la faz de la Tierra).": "Nuruk es la principal y primera ciudad del multimetaverso 3D en Reality Near. El nombre se basa en la unión de dos palabras: new (nuevo en inglés) y uruk (la primera ciudad sobre la faz de la Tierra).",
    "Podrás encontrar infinidad de actividades en nuestro multimetaverso. Por ejemplo, podrás asistir al partido de tu equipo favorito, contratar nuevos empleados para tu empresa o incluso enseñar a hablar francés. Todo depende de tu creatividad y de cómo aprovechas los beneficios que Reality Near provee.": "Podrás encontrar infinidad de actividades en nuestro multimetaverso. Por ejemplo, podrás asistir al partido de tu equipo favorito, contratar nuevos empleados para tu empresa o incluso enseñar a hablar francés. Todo depende de tu creatividad y de cómo aprovechas los beneficios que Reality Near provee.",
    "Reality Near es un multimetaverso que combina tecnología y realidad, de tal forma que los usuarios puedan desarrollarse en un nuevo ambiente completamente inmersivo y digital que, además, genera valor en su experiencia.": "Reality Near es un multimetaverso que combina tecnología y realidad, de tal forma que los usuarios puedan desarrollarse en un nuevo ambiente completamente inmersivo y digital que, además, genera valor en su experiencia.",
    "No todos tenemos las mismas oportunidades para desarrollarnos. Existen distintas limitaciones sociales, económicas, y educativas que obstaculizan el desarrollo de muchas personas. Sin embargo, la gran mayoría tiene acceso a internet y el sueño de salir adelante. Es aquí donde entra Reality Near.": "No todos tenemos las mismas oportunidades para desarrollarnos. Existen distintas limitaciones sociales, económicas, y educativas que obstaculizan el desarrollo de muchas personas. Sin embargo, la gran mayoría tiene acceso a internet y el sueño de salir adelante. Es aquí donde entra Reality Near.",
    "En Reality Near buscamos generar oportunidades para el desarrollo de los usuarios en ámbitos socioeconómicos y educativos, dentro de un ambiente descentralizado que garantiza las mismas oportunidades de crecimiento para todos. A través de la fusión de la tecnología con la realidad, se busca crear un nuevo mundo con posibilidades infinitas. Aquí podrás crecer, aprender, desarrollarte, y lo más importante: crear todo lo que puedas imaginar.": "En Reality Near buscamos generar oportunidades para el desarrollo de los usuarios en ámbitos socioeconómicos y educativos, dentro de un ambiente descentralizado que garantiza las mismas oportunidades de crecimiento para todos. A través de la fusión de la tecnología con la realidad, se busca crear un nuevo mundo con posibilidades infinitas. Aquí podrás crecer, aprender, desarrollarte, y lo más importante: crear todo lo que puedas imaginar.",
    "Este puede ser tu nuevo comienzo, solo debes dejar que tu creatividad defina el camino.": "Este puede ser tu nuevo comienzo, solo debes dejar que tu creatividad defina el camino.",
    "¿QUÉ ES REALITY NEAR?":"¿QUÉ ES REALITY NEAR?",
    "¿CUÁL ES EL PROBLEMA?": "¿CUÁL ES EL PROBLEMA?",
    "UN MUNDO DE OPORTUNIDADES": "UN MUNDO DE OPORTUNIDADES",
    "Reality Near te permite desarrollarte y hacer negocios, tales como adquirir y vender bienes a través de todas nuestras plataformas: App Reality Near,  Marketplace en el sitio web y en Nuruk.":"Reality Near te permite desarrollarte y hacer negocios, tales como adquirir y vender bienes a través de todas nuestras plataformas: App Reality Near,  Marketplace en el sitio web y en Nuruk.",
    "Para que las transacciones sean posibles, creamos tokens llamados “REALITIES”, los cuales se utilizan como moneda en nuestro metaverso. Con los realities podrás comprar y vender parcelas, así como también NFTs  o cualquier artículo a la venta. Además, podrás intercambiar bienes con los demás usuarios mediante transacciones P2P.": "Para que las transacciones sean posibles, creamos tokens llamados “REALITIES”, los cuales se utilizan como moneda en nuestro metaverso. Con los realities podrás comprar y vender parcelas, así como también NFTs  o cualquier artículo a la venta. Además, podrás intercambiar bienes con los demás usuarios mediante transacciones P2P.",
    "Con tu Near wallet, podrás administrar tus Realities, al igual que llevar registro de tus transacciones, y también podrás ver el tipo de cambio al dolar. Si no tienes una, haz clic aquí para crearla.":"Con tu Near wallet, podrás administrar tus Realities, al igual que llevar registro de tus transacciones, y también podrás ver el tipo de cambio al dolar. Si no tienes una, haz clic aquí para crearla.",
    "El Reality es transaccional con todas las Near Wallet, asi que no tendrás ningun inconveniente en transferirle a amigos. Podrás cambiar Nears a  Realities en nuestro sitio web.": "El Reality es transaccional con todas las Near Wallet, asi que no tendrás ningun inconveniente en transferirle a amigos. Podrás cambiar Nears a  Realities en nuestro sitio web.",
    "¿Cómo funciona?": "¿Cómo funciona?",
    "ADQUIRIR REALITIES": "Adquirir realities",
    "Envíanos un mensaje": "Envíanos un mensaje",
    "Nuestro equipo te responderá lo antes posible.": "Nuestro equipo te responderá lo antes posible.",
    "CONTÁCTANOS": "CONTÁCTANOS",
    "¿En qué podríamos ayudarte?":"¿En qué podríamos ayudarte?",
    "Conoce más del multimetaverso Reality Near": "Conoce más del multimetaverso Reality Near",
    "NURUK: CIUDAD DE REALIDADES": "NURUK: CIUDAD DE REALIDADES",
    "Dar un vistazo": "Dar un vistazo",
    "Reality Near App ofrece una nueva experiencia fusionando tecnología y realidad. Esta te permitirá ser parte de eventos, ver publicidad, completar misiones, ver avatares y muchas cosas más en la vida real usando sólo la cámara de tu teléfono. Dicho contenido te permitirá interactuar con la vida real a través de tu dispositivo móvil, generando una experiencia totalmente inmersiva y valiosa.": "Reality Near App ofrece una nueva experiencia fusionando tecnología y realidad. Esta te permitirá ser parte de eventos, ver publicidad, completar misiones, ver avatares y muchas cosas más en la vida real usando sólo la cámara de tu teléfono. Dicho contenido te permitirá interactuar con la vida real a través de tu dispositivo móvil, generando una experiencia totalmente inmersiva y valiosa.",
    "Todo contenido creado podrás visualizarlo en las tierras de los demás usuarios y, si posees una, podrás crear y compartir tu creación con la comunidad Reality Near.": "Todo contenido creado podrás visualizarlo en las tierras de los demás usuarios y, si posees una, podrás crear y compartir tu creación con la comunidad Reality Near.",
    "En la misma aplicación contarás con una wallet, donde administrarás tus Realities y NFTs, así como tus transacciones pasadas. También podrás añadir amigos fácilmente; enviarles y recibir mensajes; y, además, ver si están conectados o cerca tuyo.": "En la misma aplicación contarás con una wallet, donde administrarás tus Realities y NFTs, así como tus transacciones pasadas. También podrás añadir amigos fácilmente; enviarles y recibir mensajes; y, además, ver si están conectados o cerca tuyo.",
    "Conoce más de la Fundación Reality Near": "Conoce más de la Fundación Reality Near",
    "Cada día que pasa, más son las limitaciones socioecónomicas y educativas a nivel global. Muchas personas emplean la mayoría de sus recursos en conseguir oportunidades de desarrollo, las cuales no siempre garantizan que salgan adelante.":"Cada día que pasa, más son las limitaciones socioecónomicas y educativas a nivel global. Muchas personas emplean la mayoría de sus recursos en conseguir oportunidades de desarrollo, las cuales no siempre garantizan que salgan adelante.",
    "FUNDADORES": "FUNDADORES",
    "SIGUENOS": "¡SÍGUENOS!",
    "Vive la experiencia": "vive la experiencia",
    "Novedades": "Novedades",
    "Noticias": "Noticias",
    "Entérate de las últimas novedades en Reality Near": "Entérate de las últimas novedades en Reality Near",
    "Encuentra y adquiere patchas, realands y NFTs en un solo lugar.": "Encuentra y adquiere patchas, realands y NFTs en un solo lugar.",
    "Fundadores": "Fundadores",
    "Selecciona categoría": "Selecciona categoría",
    "Nombre": "Nombre",
    "Correo": "Correo",
    "Mensaje": "Mensaje",
    "Enviar": "Enviar",
    "¡SÍGUENOS!": "¡SÍGUENOS!",
    "Nuestro equipo enviará la respuesta a tu correo pronto.": "Nuestro equipo enviará la respuesta a tu correo pronto.",
    "Tu mensaje ha sido enviado correctamente.": "Tu mensaje ha sido enviado correctamente.",
    "¡MENSAJE ENVIADO!": "¡MENSAJE ENVIADO!",
    "Código de ética": "Código de ética",
    "Políticas de Privacidad": "Políticas de Privacidad",
    "Políticas de Cookies": "Políticas de Cookies",
    "Términos y condiciones": "Términos y Condiciones",
    "Ofertas": "Ofertas",
    "Otros": "Otros",
    "Artículos Similares": "Artículos Similares"
  }
}