{
  "name": "English",
  "key": "en",
  "translation":{
    "Conoce los eventos del momento": "Know the current events",
    "Buscar idioma": "Search language",
    "Metaverso": "Metaverse",
    "Contacto": "Contact",
    "Nosotros": "About",
    "Tema": "Theme",
    "Marketplace": "Marketplace",
    "Inicio": "Home",
    "Todos": "All",
    "TEMA": "THEME",
    "Iniciar sesión": "Log In",
    "Inicia sesión para continuar con la transacción": "Log in to continue the transaction",
    "Ver más": "See more",
    "Eventos": "Events",
    "Resumen de la transacción": "Transaction summary",
    "Bienvenido a": "Welcome to",
    "Bienvenido a Reality Near": "Welcome to Reality Near",
    "Novedades relacionadas": "Related News",
    "MI PERFIL": "MY PROFILE",
    "My NFTs": "My NFTs",
    "Mis tierras": "My Lands",
    "Vender": "Sell",
    "Comprar": "Buy",
    "Adquirir": "Buy",
    "Selecciona Categoria": "Select category",
    "Consulta": "Consultation",
    "Problemas con Nuruk": "Problems with Nuruk",
    "Problemas con mi wallet": "Problems with my wallet",
    "Seguimos en construcción": "We are still under construction",
    "El contenido que buscas estará disponible próximamente. Estamos trabajando para darte la mejor experiencia.": "The content you are looking for will be available soon. We are working to give you the best experience.",
    "Para que las transacciones sean posibles, creamos tokens llamados 'Realities', los cuales se utilizan como moneda en nuestro metaverso. Con estos se realizan todas las transacciones financieras dentro de este multimetaverso. Podrás comprar parcelas( Realands y Patchas ), accesorios, ropa, servicios, y todo lo que se encuentre a la venta en Nuruk." :"To make transactions possible, we create tokens called 'Realities', which are used as currency in our metaverse. With these, all financial transactions are carried out within this multimetaverse. You will be able to buy plots (Realands and Patchas), accessories, clothes, services, and everything that is for sale in Nuruk.",
    "El reality token funciona dentro del ecosistema de Near. En este ecosistema es que se almacena el registro de transacciones en la blockchain. Tus realities se almacenan en tu Near Wallet, y se verán reflejados en tu perfil de Nuruk en la app de Reality Near. En nuestro metaverso puedes realizar infinidad de compras y ventas, adquirir tesoros o NFTs; y todo esto mediante transacciones de realities.": "The reality token works within the Near ecosystem. It is in this ecosystem that the transaction log is stored on the blockchain. Your reality shows are stored in your Near Wallet, and will be reflected in your Nuruk profile in the Reality Near app. In our metaverse you can make countless purchases and sales, acquire treasures or NFTs; and all this through reality transactions.",
    "Nuruk es la principal y primera ciudad del multimetaverso 3D en Reality Near. El nombre se basa en la unión de dos palabras: new (nuevo en inglés) y uruk (la primera ciudad sobre la faz de la Tierra).": "Nuruk is the main and first city of the 3D multimetaverse in Reality Near. The name is based on the union of two words: new (new in English) and uruk (the first city on the face of the Earth).",
    "Podrás encontrar infinidad de actividades en nuestro multimetaverso. Por ejemplo, podrás asistir al partido de tu equipo favorito, contratar nuevos empleados para tu empresa o incluso enseñar a hablar francés. Todo depende de tu creatividad y de cómo aprovechas los beneficios que Reality Near provee.": "You can find countless activities in our multimetaverse. For example, you can attend the game of your favorite team, hire new employees for your company or even teach how to speak French. It all depends on your creativity and how you take advantage of the benefits that Reality Near provides.",
    "Reality Near es un multimetaverso que combina tecnología y realidad, de tal forma que los usuarios puedan desarrollarse en un nuevo ambiente completamente inmersivo y digital que, además, genera valor en su experiencia.": "Reality Near is a multimetaverse that combines technology and reality, in such a way that users can develop in a completely immersive and digital new environment that also generates value in their experience.",
    "No todos tenemos las mismas oportunidades para desarrollarnos. Existen distintas limitaciones sociales, económicas, y educativas que obstaculizan el desarrollo de muchas personas. Sin embargo, la gran mayoría tiene acceso a internet y el sueño de salir adelante. Es aquí donde entra Reality Near.": "Not everyone has the same opportunities to develop. There are different social, economic, and educational limitations that hinder the development of many people. However, the vast majority have access to the internet and the dream of getting ahead. This is where Reality Near comes in.",
    "En Reality Near buscamos generar oportunidades para el desarrollo de los usuarios en ámbitos socioeconómicos y educativos, dentro de un ambiente descentralizado que garantiza las mismas oportunidades de crecimiento para todos. A través de la fusión de la tecnología con la realidad, se busca crear un nuevo mundo con posibilidades infinitas. Aquí podrás crecer, aprender, desarrollarte, y lo más importante: crear todo lo que puedas imaginar.": "At Reality Near we seek to generate opportunities for the development of users in socioeconomic and educational fields, within a decentralized environment that guarantees the same growth opportunities for everyone. Through the fusion of technology with reality, it seeks to create a new world with infinite possibilities. Here you can grow, learn, develop, and most importantly: create everything you can imagine.",
    "Este puede ser tu nuevo comienzo, solo debes dejar que tu creatividad defina el camino.": "This can be your new beginning, you just have to let your creativity define the way.",
    "¿QUÉ ES REALITY NEAR?":"WHAT IS REALITY NEAR?",
    "¿CUÁL ES EL PROBLEMA?": "WHAT IS THE PROBLEM?",
    "UN MUNDO DE OPORTUNIDADES": "A WORLD OF OPPORTUNITIES",
    "Reality Near te permite desarrollarte y hacer negocios, tales como adquirir y vender bienes a través de todas nuestras plataformas: App Reality Near,  Marketplace en el sitio web y en Nuruk.":"Reality Near allows you to develop and do business, such as buying and selling goods through all our platforms: App Reality Near, Marketplace on the website and in Nuruk.",
    "Para que las transacciones sean posibles, creamos tokens llamados “REALITIES”, los cuales se utilizan como moneda en nuestro metaverso. Con los realities podrás comprar y vender parcelas, así como también NFTs  o cualquier artículo a la venta. Además, podrás intercambiar bienes con los demás usuarios mediante transacciones P2P.": "To make transactions possible, we create tokens called “REALITIES”, which are used as currency in our metaverse. With the realities you will be able to buy and sell plots, as well as NFTs or any item for sale. In addition, you will be able to exchange goods with other users through P2P transactions.",
    "Con tu Near wallet, podrás administrar tus Realities, al igual que llevar registro de tus transacciones, y también podrás ver el tipo de cambio al dolar. Si no tienes una, haz clic aquí para crearla.":"With your Near wallet, you will be able to manage your Realities, as well as keep track of your transactions, and you will also be able to see the exchange rate to the dollar. If you don't have one, click here to create one.",
    "El Reality es transaccional con todas las Near Wallet, asi que no tendrás ningun inconveniente en transferirle a amigos. Podrás cambiar Nears a  Realities en nuestro sitio web.": "Reality is transactional with all Near Wallets, so you won't have any problem transferring to friends. You will be able to change Nears to Realities on our website.",
    "¿Cómo funciona?": "How does it work?",
    "ADQUIRIR REALITIES": "Acquire realities",
    "Envíanos un mensaje": "Send us a message",
    "Nuestro equipo te responderá lo antes posible.": "Our team will respond to you as soon as possible.",
    "CONTÁCTANOS": "CONTACT US",
    "¿En qué podríamos ayudarte?":"How could we help you?",
    "Conoce más del multimetaverso Reality Near": "Learn more about the Reality Near multimetaverse",
    "NURUK: CIUDAD DE REALIDADES": "NURUK: CITY OF REALITIES",
    "Dar un vistazo": "Have a look",
    "Reality Near App ofrece una nueva experiencia fusionando tecnología y realidad. Esta te permitirá ser parte de eventos, ver publicidad, completar misiones, ver avatares y muchas cosas más en la vida real usando sólo la cámara de tu teléfono. Dicho contenido te permitirá interactuar con la vida real a través de tu dispositivo móvil, generando una experiencia totalmente inmersiva y valiosa.":"Reality Near App offers a new experience merging technology and reality. This will allow you to be part of events, see advertisements, complete missions, see avatars and much more in real life using only your phone's camera. Said content will allow you to interact with real life through your mobile device, generating a totally immersive and valuable experience.",
    "Todo contenido creado podrás visualizarlo en las tierras de los demás usuarios y, si posees una, podrás crear y compartir tu creación con la comunidad Reality Near.": "All created content can be viewed on the lands of other users and, if you own one, you can create and share your creation with the Reality Near community.",
    "En la misma aplicación contarás con una wallet, donde administrarás tus Realities y NFTs, así como tus transacciones pasadas. También podrás añadir amigos fácilmente; enviarles y recibir mensajes; y, además, ver si están conectados o cerca tuyo.": "In the same application you will have a wallet, where you will manage your Realities and NFTs, as well as your past transactions. You can also easily add friends; send and receive messages to them; and, in addition, see if they are connected or near you.",
    "Conoce más de la Fundación Reality Near":"Learn more about the Reality Near Foundation",
    "Cada día que pasa, más son las limitaciones socioecónomicas y educativas a nivel global. Muchas personas emplean la mayoría de sus recursos en conseguir oportunidades de desarrollo, las cuales no siempre garantizan que salgan adelante.":"Every day that passes, there are more socioeconomic and educational limitations at a global level. Many people use most of their resources to get development opportunities, which do not always guarantee that they will get ahead.",
    "FUNDADORES": "FOUNDERS",
    "SIGUENOS": "¡FOLLOW US!",
    "Vive la experiencia": "Live the experience",
    "Novedades": "Novelties",
    "Noticias": "News",
    "Entérate de las últimas novedades en Reality Near": "Find out about the latest news in Reality Near",
    "Encuentra y adquiere patchas, realands y NFTs en un solo lugar.": "Find and buy patches, realands and NFTs in one place.",
    "Fundadores": "Founders",
    "Selecciona categoría": "Select category",
    "Nombre": "Name",
    "Correo": "E-mail",
    "Mensaje": "Message",
    "Enviar": "Send",
    "¡SÍGUENOS!": "¡FOLLOW US!",
    "Noticias Relacionadas": "Related news",
    "Conoce las nuevas actualizaciones en el mundo de Reality Near": "Meet the new updates in the world of Reality Near",
    "Nuestro equipo enviará la respuesta a tu correo pronto.": "Our team will send the answer to your email soon.",
    "Tu mensaje ha sido enviado correctamente.": "Your message has been sent successfully.",
    "¡MENSAJE ENVIADO!": "MESSAGE SENT!",
    "Código de ética": "Code of ethics",
    "Políticas de Privacidad": "Privacy policies",
    "Políticas de Cookies": "Cookie Policies",
    "Términos y condiciones": "Terms and Conditions",
    "Ofertas": "Offers",
    "Otros": "Others",
    "¿Cuántos Realities desea?": "How many realities do you want?",
    "Ingresa tu near Wallet": "Enter your near Wallet",
    "ACEPTAR": "OK",
    "Artículos Similares": "Similar Items",
    "Política de privacidad": "Privacy policies",
    "Política de cookies": "Privacy cookies",
    "Eventos relacionados": "Related Events",
    "Selecciona categoria": "Select category",
    "TRABAJANDO EN EL PROTOCOLO NEAR": "WORKING ON PROTOCOL NEAR",
    "Los Patchas son NFTs sujetos a una latitud y longitud específica del plano terrenal, o sea, cada parcela cuadricular representa un espacio real. En este espacio podrás organizar eventos para la comunidad, colocar tesoros, cumplir misiones, recibir recompensas, colocar publicidad, entre muchas cosas más. Si te encuentras físicamente en un punto real vinculado a un Patcha, a través la app de Reality Near en tu celular, podrás interactuar mediante realidad aumentada con lo que se haya colocado sobre dicho espacio.": "Patchas are NFTs subject to a specific latitude and longitude of the earthly plane, that is, each grid plot represents a real space. In this space you can organize events for the community, place treasures, complete missions, receive rewards, place advertisements, among many other things. If you are physically in a real point linked to a Patcha, through the Reality Near app on your cell phone, you will be able to interact through augmented reality with what has been placed on said space.",
    "Estas son parcelas de 10x10mts cada una que se encuentran dentro de Reality Near, las cuales podrás comprar para construir tu negocio, poner publicidad, hacer eventos, lo que quieras. También podrás comprar Realands adyacentes y elegir si deseas combinarlos, formando un Realand más grande; por ejemplo, un 2x3 o un 5x5. El máximo de Realands que se pueden combinar son de 25 Realands en total. Una vez combinados, pueden mantenerse así y venderse como un todo, o dividirse para una venta por partes.": "These are plots of 10x10 meters each that are located within Reality Near, which you can buy to build your business, advertise, hold events, whatever you want. You will also be able to buy adjacent Realands and choose if you want to combine them, forming a larger Realand; for example, a 2x3 or a 5x5. The maximum number of Realands that can be combined is 25 Realands in total. Once combined, they can be kept that way and sold as a whole, or broken down for a piecemeal sale."
  }
}